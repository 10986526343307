<template>
  <div class="dashboard workout-pt-appointment-page">
    <div class="workout-pt-appointment-top">
      <tabs/>
    </div>
    <div class="workout-pt-appointment-content">
      <div class="container">
          <dateSliderFilter/>
          <div class="workout-pt-appointment-list">
            <div class="workout-pt-appointment-list-section">
              <p class="section-title">Esta Semana</p>
              <div class="workout-calendar-list-item layout-2">
                <a href="" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="workout-calendar-list-item-time d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-date">22</p>
                      <p class="workout-calendar-list-item-hour">11.00-12.00</p>
                    </div>
                    <div class="workout-calendar-list-item-info d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-title">Treino Personalizado</p>
                      <p class="workout-calendar-list-item-subtitle">com Philip Johnston</p>
                    </div>
                  </div>
                  <div class="workout-calendar-list-item-btn">
                    <chevronLeft/>
                  </div>
                </a>
              </div>
            </div>
            <div class="workout-pt-appointment-list-section">
              <p class="section-title">Próximas Semanas</p>
              <div class="workout-calendar-list-item layout-2">
                <a href="" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="workout-calendar-list-item-time d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-date">10</p>
                      <p class="workout-calendar-list-item-hour">11.00-12.00</p>
                    </div>
                    <div class="workout-calendar-list-item-info d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-title">Treino Personalizado</p>
                      <p class="workout-calendar-list-item-subtitle">com Philip Johnston</p>
                    </div>
                  </div>
                  <div class="workout-calendar-list-item-btn">
                    <chevronLeft/>
                  </div>
                </a>
              </div>
              <div class="workout-calendar-list-item layout-2">
                <a href="" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="workout-calendar-list-item-time d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-date">17</p>
                      <p class="workout-calendar-list-item-hour">11.00-12.00</p>
                    </div>
                    <div class="workout-calendar-list-item-info d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-title">Treino Personalizado</p>
                      <p class="workout-calendar-list-item-subtitle">com Philip Johnston</p>
                    </div>
                  </div>
                  <div class="workout-calendar-list-item-btn">
                    <chevronLeft/>
                  </div>
                </a>
              </div>
              <div class="workout-calendar-list-item layout-2">
                <a href="" class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="workout-calendar-list-item-time d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-date">24</p>
                      <p class="workout-calendar-list-item-hour">11.00-12.00</p>
                    </div>
                    <div class="workout-calendar-list-item-info d-xl-flex justify-content-start align-items-center">
                      <p class="workout-calendar-list-item-title">Treino Personalizado</p>
                      <p class="workout-calendar-list-item-subtitle">com Philip Johnston</p>
                    </div>
                  </div>
                  <div class="workout-calendar-list-item-btn">
                    <chevronLeft/>
                  </div>
                </a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import tabs from "@/components/workouts/workout-pt-tabs.vue";
import dateSliderFilter from "@/components/date-slider-filter.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

export default {
  name: "workout-pt-appointment",
  components : {
    tabs,
    chevronLeft,
    dateSliderFilter
  },
}
</script>