<template>
  <div class="slider-filter-wrapper date-slider-filter d-flex justify-content-between align-items-center">
    <button class="slider-filter-arrow slider-filter-arrow-prev d-flex justify-content-center align-items-center justify-content-xl-start" @click="prevSlide">
      <div class="slider-filter-arrow-icon">
        <chevronLeft/>
      </div>
      <p class="d-none d-xl-block">2020</p>
    </button>
    <div class="slider-filter-slider-wrapper">
      <Slick ref="slick" :options="slickOptions" class="slider-filter-slider">
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Jan</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content filter-active">
            <p>Fev</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Mar</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Abr</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Mai</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Jun</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Jul</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Ago</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Set</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Out</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Nov</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content">
            <p>Dez</p>
          </div>
        </div>
      </Slick>
    </div>
    <button class="slider-filter-arrow slider-filter-arrow-next d-flex justify-content-center align-items-center justify-content-xl-start" @click="nextSlide">
      <p class="d-none d-xl-block">2022</p>
      <div class="slider-filter-arrow-icon">
        <chevronRight/>
      </div>
    </button>
  </div>
</template>

<script>
import Slick from "vue-slick";
import chevronLeft from "@/components/svg/chevron-left.vue";
import chevronRight from "@/components/svg/chevron-right.vue";

export default {
  name: "slider-filter",
  components: {
    Slick,
    chevronLeft,
    chevronRight,
  },
  data: function () {
    return {
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: false,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true,
      },
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  },
}
</script>